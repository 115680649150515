interface ParsedFileName {
  base: string;
  num: number;
  ext: string;
}

const parseFileName = (fileName: string): ParsedFileName => {
  const match = fileName.match(/^(.*?)(?:\s(\d+))?\.(.*?)$/);
  if (!match) return { base: fileName, num: 0, ext: '' };

  const [, base, num, ext] = match;
  return {
    base: base,
    num: num ? parseInt(num, 10) : 0,
    ext: ext,
  };
};

const sortFiles = (files: string[]): string[] => {
  return [...files].sort((a, b) => {
    const fileA = parseFileName(a);
    const fileB = parseFileName(b);

    // First compare base names
    const baseCompare = fileA.base.localeCompare(fileB.base);
    if (baseCompare !== 0) return baseCompare;

    // If base names are same, compare numbers
    return fileA.num - fileB.num;
  });
};

export { sortFiles };
