import {
  convertToBase64,
  findDuplicateNames,
  IFile,
  ISelectOption,
  MyIcon,
  normalizeWhitespace,
  PreviewFile,
} from '@scanny-app/loopy-loop';
import { ChangeEvent, DragEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd';

interface MultiFileUploadProps {
  FileOptions: ISelectOption[];
  handleFilesUpdate: (files: IFile[]) => void;
  form: FormInstance;
  existingFiles?: string[];
}

type IndexedFile = {
  file: File;
  id: string;
  type?: string;
  extension?: string;
};

function MultiFileUpload({ FileOptions, form, handleFilesUpdate, existingFiles }: MultiFileUploadProps) {
  const [files, setFiles] = useState<IndexedFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const generateUnique = require('uuid/v4');
  const formatFiles = files.map(({ file }) => file);
  const onDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    addFiles(event.dataTransfer.files);
    setIsFocused(false);
  }, []);

  const focusElement = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsFocused(true);
  };
  const unFocusElement = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsFocused(false);
  };

  const handleFile = (file: File, id: string) => {
    setFiles((prevFiles) => [...prevFiles, { file, id }]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  const addFiles = (files: FileList | null) => {
    if (!files) return;
    for (let i = 0; i < files?.length; i++) {
      if (files?.[i]) {
        const uniqueId = generateUnique();
        handleFile(files[i], uniqueId);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    addFiles(event.target.files);
  };
  const deleteId = (id: string) => {
    cleanFileNames('', '', id);
  };
  const cleanFileNames = (value: string, id: string, deleteId?: string) => {
    const filesNamed: IndexedFile[] = [];
    setFiles((prevFiles) =>
      prevFiles
        .filter((prevFile) => !deleteId || prevFile.id !== deleteId)
        .map((prevFile) => {
          const currFiles = filesNamed.map(({ file }) => file);
          const { file } = prevFile;
          if (id && value) {
            if (prevFile.id === id) prevFile.type = value;
          }
          if (prevFile.type) {
            const normalizePrevFileType = normalizeWhitespace(prevFile.type);

            const maxNum = findDuplicateNames({
              formatFiles: currFiles,
              existingFiles: existingFiles,
              targetValue: normalizePrevFileType,
            });
            const extension = file.name.split('.').pop();
            const newFileName = `${normalizePrevFileType}${maxNum !== 0 ? ` ${maxNum}` : ''}.${extension}`;
            const updatedFile = new File([file], newFileName, {
              type: file.type,
            });
            const currFile: IndexedFile = {
              file: updatedFile,
              id: prevFile.id,
              type: normalizePrevFileType,
              extension: extension,
            };
            filesNamed.push(currFile);

            return currFile;
          }
          return prevFile;
        }),
    );
  };

  const updateName = (value: string, id: string) => {
    if (value === 'Other') {
      form.setFieldsValue({ [`files[${id}].name`]: '' });
      setFiles((prevFiles) =>
        prevFiles.map((prevFile) =>
          prevFile.id === id
            ? {
                ...prevFile,
                file: new File([prevFile.file], `.${prevFile.file.name.split('.').pop()}`, { type: prevFile.file.type }),
              }
            : prevFile,
        ),
      );
      return;
    }
    cleanFileNames(value, id);
  };

  const onZoneClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    const convertFiles = async (files: File[]) => {
      const customFiles: IFile[] = [];

      for (const file of files) {
        const base64Data = await convertToBase64(file);
        customFiles.push({
          title: file.name.split('.')[0] || 'Document',
          name: file.name,
          data: base64Data,
        });
      }
      handleFilesUpdate(customFiles);
    };

    convertFiles(formatFiles).then();
  }, [files]);

  return (
    <div className="gap-8 flex flex-column">
      <div
        className={`noselect w-full content-center h-55 flex middle gap-12 flex-row btn-status font-normal`}
        style={{
          backgroundColor: isFocused ? '#3CCEB3' : '#FFFFFF',
          color: isFocused ? '#FFFFFF' : '#3CCEB3',
          border: '1px dashed #3CCEB3',
          boxSizing: 'border-box',
        }}
        onClick={onZoneClick}
        onDrop={onDrop}
        onDragOver={focusElement}
        onDragEnd={unFocusElement}
        onDragLeave={unFocusElement}
        onMouseEnter={focusElement}
        onMouseLeave={unFocusElement}
      >
        Upload documents
        <MyIcon icon="IconUploadCloud" fill={isFocused ? '#FFFFFF' : '#3CCEB3'} />
      </div>
      {files.length > 0 && (
        <div>
          <div className="grid-1fr-2fr divider-dropdown">
            <h4 className="font-normal" style={{ marginBottom: '5px' }}>
              Type
            </h4>
            <h4 className="font-normal" style={{ marginBottom: '5px' }}>
              Document
            </h4>
          </div>
          {files.map((file) => (
            <PreviewFile
              key={file.id}
              id={file.id}
              file={file.file}
              FileOptions={FileOptions}
              deleteIndex={() => deleteId(file.id)}
              updateName={(value) => updateName(value, file.id)}
            />
          ))}
        </div>
      )}
      <input
        accept="image/*,application/pdf"
        multiple
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </div>
  );
}

export default MultiFileUpload;
