import { normalizeWhitespace } from '@scanny-app/loopy-loop';

type File = {
  name: string;
};

interface IFindDuplicateNames {
  formatFiles: File[];
  existingFiles: string[] | undefined;
  targetValue: string;
}

// Function to remove the number and extension from a file name
const removeNumberAndExtension = (fileName: string): string => {
  const match = fileName.match(/^(.*?)(?:\s\d+)?\.[a-z]+$/i);
  return match ? match[1] : fileName;
};

// Function to extract the number from a file name
const extractNumber = (fileName: string, targetValue: string): number | undefined => {
  const normalizeFormatFileName = normalizeWhitespace(removeNumberAndExtension(fileName));
  const normalizeFileName = normalizeWhitespace(fileName);

  if (normalizeFormatFileName === targetValue) {
    const regex = new RegExp(`^${targetValue}(?:\\s(\\d+))?\\.\\w+$`, 'i');
    const match = normalizeFileName.match(regex);
    if (match) {
      // If there's no number (match[1] is undefined), return 0
      if (!match[1]) {
        return 0;
      }
      const matchedValue = parseInt(match[1], 10);
      return Number.isNaN(matchedValue) ? 0 : matchedValue;
    }
    return 0; // Return 0 for base filename without number
  }
  return undefined;
};

// Main function to find duplicate names and assign new numbers
const findDuplicateNames = ({ formatFiles, existingFiles, targetValue }: IFindDuplicateNames): number => {
  if (!targetValue) {
    return 0;
  }
  const numbers: number[] = []; // Array to store numbers from `existingFiles`
  // Find the maximum number in `existingFiles`
  let maxNumber = 0;
  if (existingFiles) {
    existingFiles.forEach((file) => {
      const number = extractNumber(file, targetValue);
      if (number !== undefined) {
        const newNumber = number + 1;
        numbers.push(newNumber);
      }
    });
  }

  if (formatFiles) {
    formatFiles.forEach((file) => {
      const number = extractNumber(file.name, targetValue);
      if (number !== undefined) {
        const newNumber = number + 1;
        numbers.push(newNumber);
      }
    });
  }

  if (numbers.length > 0) {
    maxNumber = Math.max(...numbers); // Get the maximum number from `existingFiles`
  }
  // If maxNumber is 0, do not assign new numbers
  if (numbers.length === 0) {
    return 0;
  }

  return maxNumber;
};

export { findDuplicateNames, removeNumberAndExtension };
