import {
  PercentagePieChart,
  UserContext,
  PrimaryNavigation,
  Total,
  IStringKeyNum,
  BannerReferCustomer,
  BannerMelbourneCup,
  BannerSummer,
  logger,
  ILog,
} from '@scanny-app/loopy-loop';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
interface IDashboardProps {
  dashboardData: IStringKeyNum;
  toggleSidebar?: (() => void) | undefined;
  sidebarVisibleInMobile?: boolean | undefined;
}
function Dashboard({ dashboardData, toggleSidebar, sidebarVisibleInMobile }: IDashboardProps) {
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const _rightNode = (
    <button className="btn-submit" onClick={() => navigate('/referrals/create-referral')}>
      Refer a customer
    </button>
  );

  const handleBanner = () => {
    logger.push({
      email: userData?.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      sourceURL: window.location.href,
      companySfID: userData?.currentCompany,
      companyName: userData?.companyName,
      requestType: 'POST',
      endpoint: `${process.env.REACT_APP_ENDPOINT_URL}/v1/fetch/file/Referral`,
      status: `Banner Clicked`,
      description: 'Banner summer clicked by the user',
      environment: process.env.REACT_APP_NODE_ENV || 'production',
    } as ILog);

    window.open('https://carbiz.createsend1.com/t/y-e-cputuy-l-j/', '_blank');
  };

  const isLoading = dashboardData['isLoading'] === 0;
  return (
    <>
      <PrimaryNavigation
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisibleInMobile}
        rightNode={_rightNode}
        menuTitle={`Dashboard`}
        menuIcon={'IconDashboard'}
        classContainer={'container-800'}
      />
      <div className="container-800 p-24 flex flex-column gap-24 md-w-fix">
        <BannerSummer handleBanner={handleBanner} />
        <div className="flex flex-row md-flex-column gap-32 md-gap-32">
          <div className={`w-30 flex flex-column md-w-full pr-24 border-r-grey`}>
            <div className="flex flex-column mb-12 form-style-one gap-4">
              <p className="info-desc m-0">Viewing</p>
              <div className="box p-8">{userData?.companyName}</div>
            </div>

            <Total
              title="Total Referrals"
              totalSize={dashboardData['total']}
              viewAllRedirect="/referrals"
              isLoading={isLoading}
              addViewAll
            />
          </div>
          <div className="w-70 grid grid-cols-3 gap-y-32 gap-x-8 md-w-full md-grid-cols-2">
            <PercentagePieChart
              title={'New'}
              total={dashboardData['total']}
              count={dashboardData['New'] ? dashboardData['New'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#FFF857', '100%': '#FFF857' }}
            />
            <PercentagePieChart
              title={'Assessing'}
              total={dashboardData['total']}
              count={dashboardData['Assessing'] ? dashboardData['Assessing'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#FFD649', '100%': '#FFD649' }}
            />
            <PercentagePieChart
              title={'Booking'}
              total={dashboardData['total']}
              count={dashboardData['Booking'] ? dashboardData['Booking'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#F9B768', '100%': '#F9B768' }}
            />

            <PercentagePieChart
              title={'On Hire'}
              total={dashboardData['total']}
              count={dashboardData['On Hire'] ? dashboardData['On Hire'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#8CC5F8', '100%': '#8CC5F8' }}
            />

            <PercentagePieChart
              title={'Off Hire'}
              total={dashboardData['total']}
              count={dashboardData['Off Hire'] ? dashboardData['Off Hire'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#8CC5F8', '100%': '#8CC5F8' }}
            />

            <PercentagePieChart
              title={'Recovery'}
              total={dashboardData['total']}
              count={dashboardData['Recovery'] ? dashboardData['Recovery'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#6FFAAE', '100%': '#6FFAAE' }}
            />

            <PercentagePieChart
              title={'Settled'}
              total={dashboardData['total']}
              count={dashboardData['Settled'] ? dashboardData['Settled'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#ABFF94', '100%': '#ABFF94' }}
            />

            <PercentagePieChart
              title={'Cancelled'}
              total={dashboardData['total']}
              count={dashboardData['Cancelled'] ? dashboardData['Cancelled'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#DCDCDC', '100%': '#DCDCDC' }}
            />

            <PercentagePieChart
              title={'Terminated'}
              total={dashboardData['total']}
              count={dashboardData['Terminated'] ? dashboardData['Terminated'] : 0}
              isLoading={isLoading}
              link="/referrals"
              conicColors={{ '0%': '#FF7D7D', '100%': '#FF7D7D' }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
