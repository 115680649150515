import React, { useEffect, useState } from 'react';

interface IBannerSummerr {
  handleBanner: () => void;
}

const BannerSummer = ({ handleBanner }: IBannerSummerr) => {
  const [widthMobile, setWidthMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setWidthMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {widthMobile ? (
        <div className="overflow-hidden rounded-12 hover-pointer" onClick={handleBanner}>
          <img src="/assets/img-summer-mobile.webp" alt="The Melbourne Cup Title" className="banner-mobile" />
        </div>
      ) : (
        <div
          className="dashboard-banner md-flex-column gap-12 bg-summer content-space-between text-white md-hidden hover-pointer"
          onClick={handleBanner}
        >
          <img src="/assets/img-summer-duck.webp" alt="The Melbourne Cup Title" style={{ height: '101%', marginLeft: 40 }} />
          <img src="/assets/img-summer-title.webp" alt="The Melbourne Cup Title" style={{ height: '80%', marginRight: 60 }} />
        </div>
      )}
    </>
  );
};

export default BannerSummer;
