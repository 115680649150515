import { getOffset } from '@scanny-app/loopy-loop';

export const getCurrentDateTime = () => {
  const date = new Date();

  const options: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('en-AU', options);
  const parts = formatter.formatToParts(date);

  const year = parts.find((part) => part.type === 'year')?.value || '0000';
  const month = parts.find((part) => part.type === 'month')?.value || '00';
  const day = parts.find((part) => part.type === 'day')?.value || '00';
  const hours = parts.find((part) => part.type === 'hour')?.value || '00';
  const minutes = parts.find((part) => part.type === 'minute')?.value || '00';
  const seconds = parts.find((part) => part.type === 'second')?.value || '00';
  const padZero = (num: number, length: number) => String(num).padStart(length, '0');
  const milliseconds = padZero(date.getMilliseconds(), 3);

  const formattedOffset = getOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${formattedOffset}`;
};
