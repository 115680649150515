import {
  ThirdNavigation,
  SmallLoading,
  SpeedUpReferral,
  ReferCustomer,
  IFile,
  FooterNavigation,
  capitalizeWords,
  getCurrentDateTime,
  logger,
  UserContext,
  ILog,
} from '@scanny-app/loopy-loop';
import { useNavigate } from 'react-router-dom';
import { Form, message, Row } from 'antd';
import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

function CreateReferral() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [accidentId, setAccidentId] = useState<string>('');
  const [files, setFiles] = useState<IFile[]>([]);
  const userData = useContext(UserContext);
  const onBack = () => {
    navigate('/referrals');
  };
  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      return;
    }
    setIsLoading(true);
    const data = form.getFieldsValue();

    const formatValues = {
      ...data,
      customerStreet: capitalizeWords(form.getFieldValue('customerStreet')),
      customerStreetLine2: capitalizeWords(form.getFieldValue('customerStreetLine2')),
      customerCity: capitalizeWords(form.getFieldValue('customerCity')),
      currentDateTime: getCurrentDateTime(),
      repairStartDate: `${data.repairStartDate?.format('YYYY-MM-DD')}`,
      damagedCarState: data.damagedCarState,
    };

    if (!accidentId) {
      Auth.currentSession().then((sessionData) => {
        console.log(formatValues);
        const token = sessionData.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/Referral`, JSON.stringify(formatValues), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            message.success('Referral created successfully');
            setAccidentId(response.data.accidentId);
            form.resetFields();
            setIsLoading(false);
            logger.push({
              sourceURL: window.location.href,
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              companySfID: userData?.currentCompany,
              companyName: userData?.companyName,
              requestType: response.request.requestType,
              status: '200',
              description: 'create referral',
              environment: process.env.REACT_APP_NODE_ENV || 'production',
            } as ILog);
          })
          .catch((error) => {
            logger.push({
              sourceURL: window.location.href,
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              companySfID: userData?.currentCompany,
              companyName: userData?.companyName,
              requestType: error.requestType,
              endpoint: error.endpoint,
              status: error.statusCode,
              message: error.message,
              description: 'create referral',
              environment: process.env.REACT_APP_NODE_ENV || 'production',
            } as ILog);
            message.error('Error creating referral');
            setIsLoading(false);
          });
      });
    } else {
      const speedUpReferralData = {
        files: files,
        id: accidentId,
        additionalComments: data.additionalComments,
        atFaultPartyClaimNumber: data.atFaultPartyClaimNumber,
        atFaultPartyInsurer: data.atFaultPartyInsurer,
        customerClaimNumber: data.customerClaimNumber,
        customerInsurer: data.customerInsurer,
      };
      Auth.currentSession().then((sessionData) => {
        const token = sessionData.getAccessToken().getJwtToken();
        axios
          .post(
            `${process.env.REACT_APP_ENDPOINT_URL}/update/Referral/${accidentId}`,
            JSON.stringify({ ...speedUpReferralData }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((response) => {
            setIsLoading(false);
            message.success('Referral updated successfully');
            setAccidentId(response.data.accidentId);
            form.resetFields();
            setIsLoading(false);
            navigate(`/referrals/${accidentId}`);
            logger.push({
              sourceURL: window.location.href,
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              companySfID: userData?.currentCompany,
              companyName: userData?.companyName,
              requestType: response.request.requestType,
              status: '200',
              description: 'speed up referral',
              environment: process.env.REACT_APP_NODE_ENV || 'production',
            } as ILog);
          })
          .catch((error) => {
            message.error('Error updating referral');
            setIsLoading(false);
            logger.push({
              sourceURL: window.location.href,
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              companySfID: userData?.currentCompany,
              companyName: userData?.companyName,
              requestType: error.requestType,
              endpoint: error.endpoint,
              status: error.statusCode,
              message: error.message,
              description: 'speed up referral',
              environment: process.env.REACT_APP_NODE_ENV || 'production',
            } as ILog);
          });
      });
    }
  };
  const [form] = Form.useForm();
  const Node = (
    <div className={'flex content-space-between w-full'}>
      <>
        {accidentId ? (
          <button className="btn-outline" disabled={isLoading} onClick={() => navigate(`/referrals/${accidentId}`)}>
            Later
          </button>
        ) : (
          <div></div>
        )}
        <button className="btn-submit" disabled={isLoading} onClick={() => handleSubmit()}>
          Submit
        </button>
      </>
    </div>
  );
  const handleFilesUpdate = (files: IFile[]) => {
    setFiles(files);
  };
  return (
    <>
      <ThirdNavigation
        menuTitle={accidentId ? 'Speed up your referral' : 'Refer a customer'}
        onBack={onBack}
        slug={'Referrals'}
      />
      <div id="inner-content" className="container-2">
        {isLoading ? (
          <div className="md-w-auto" style={{ height: 240 }}>
            <SmallLoading customText="Just a moment, we're submitting your referral..." />
          </div>
        ) : (
          <Form className="w-full form-style-one" layout="vertical" form={form}>
            <Row gutter={[16, 16]}>
              {accidentId ? <SpeedUpReferral handleFilesUpdate={handleFilesUpdate} form={form} /> : <ReferCustomer form={form} />}
            </Row>
          </Form>
        )}
      </div>

      <FooterNavigation node={Node} />
    </>
  );
}

export default CreateReferral;
