import { Select, Form, Input } from 'antd';
import { MyIcon, ISelectOption, PreviewModal, removeNumberAndExtension } from '@scanny-app/loopy-loop';
import { useState } from 'react';
interface PreviewFileProps {
  FileOptions: ISelectOption[];
  file: File;
  id: string;
  deleteIndex: (id: string) => void;
  updateName: (value: string, id: string) => void;
}
function PreviewFile({ FileOptions, file, id, deleteIndex, updateName }: PreviewFileProps) {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isOther, setIsOther] = useState<boolean>(false);
  const formatFileName = removeNumberAndExtension(file.name);

  const handlePreview = (file: File) => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleSelection = (value: string) => {
    if (value === 'Other') {
      setIsOther(true);
    }
    updateName(value, id);
  };

  return (
    <div className="grid-1fr-2fr py-12 border-b-grey">
      <div className="p-4">
        <Form.Item
          name={`files[${id}].name`}
          rules={[
            {
              required: true,
              message: 'Please add the file type',
            },
            {
              pattern: /^[a-zA-Z0-9_\s]+$/,
              message: 'Only letters, numbers, spaces and underscores are allowed',
            },
          ]}
        >
          {isOther ? (
            <Input
              placeholder={'Describe the file here...'}
              maxLength={100}
              onChange={(value) => handleSelection(value.target.value)}
            />
          ) : (
            <Select className="w-full h-full" placeholder={'Select an option'} onSelect={handleSelection}>
              {FileOptions.map((option, index) => (
                <Option key={index} value={option.value} disabled={formatFileName === option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>
      <div className="p-4 grid w-full" style={{ gridTemplateColumns: '1fr 40px' }}>
        <div
          className="p-12 grid md-middle btn-outline-grey icon-m gap-12"
          style={{ height: '19px', alignItems: 'center', gridTemplateColumns: '20px 1fr' }}
          onClick={() => handlePreview(file)}
        >
          <MyIcon icon="IconPreAuth" /> <p className="truncate m-0">{file.name}</p>
        </div>
        <div className="p-4 middle content-center flex cursor-pointer" onClick={() => deleteIndex(id)}>
          <MyIcon icon="IconDecline" />
        </div>
      </div>
      <PreviewModal onClose={handleModalClose} file={file} visible={isModalVisible} />
    </div>
  );
}

export default PreviewFile;
